<template>
  <div v-if="hasError">
    <div v-if="showErrors" class="border-[10px] border-solid border-red">
      <span v-if="tag">ERROR in {{ tag }}: {{ errorBag[0] }}</span>
      <span v-else>ERROR: {{ errorBag[0] }}</span>
      <pre>
        {{ errorBag[1] }}
      </pre>
    </div>
    <div v-else-if="showFallback">This content is no longer available.</div>
  </div>

  <Suspense>
    <slot></slot>
    <template #fallback></template>
  </Suspense>
</template>

<script setup>
  import { report } from '~/utils/telemetry';

  const props = defineProps({
    tag: {
      required: false,
      default: null,
      type: String,
    },
    showFallback: {
      required: false,
      default: false,
      type: Boolean,
    },
  });

  const { isSandbox, isStaging, isProduction } = useRuntimeConfig().public;

  const errorBag = ref([]);
  const hasError = computed(() => errorBag.value.length > 0);
  const showErrors = isSandbox || isStaging;
  const logErrors = isProduction;

  onErrorCaptured((error) => {
    if (error && error.statusCode === 404) {
      // Do not block redirecting to the 404 if we are
      // intentionally throwing a 404 error.
      return true;
    }
    const errorMessage = error.stack
      ? error.stack.split('\n')[0]
      : 'Unknown error';
    const firstLine = error.stack
      ? error.stack.split('\n')[1]
      : 'No additional information';

    // this will log the errors in the console
    // TODO print whole stacktrace on page in dev mode
    // TODO we could send a error to sentry here
    errorBag.value = [errorMessage, firstLine];

    if (showErrors) {
      console.error('Error in boundary', props.tag);
      console.error(error);
    }

    if (logErrors) {
      report(error);
    }

    return false;
  });
</script>

<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
